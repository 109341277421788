@import url("https://fonts.cdnfonts.com/css/satoshi");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Satoshi", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--fontWhite);
  background: #0a0809;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --backGroundBlack: #1e1717;
  --fontWhite: #ffffff;
  --foregroundBlack: #111111;
  --secondaryTextColor: #bab5b5;
  --yellow: #ffd91b;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--backGroundBlack);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(98, 98, 98);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  box-sizing: border-box;
}

.header {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffd91b;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 2px dashed rgba(255, 217, 27, 0.17);
  border-top: 2px dashed rgba(255, 217, 27, 0.17);
}

.topStoriesHeader {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.breakingNewsHeader {
  width: 30%;
  border-left: 2px solid rgba(241, 231, 234, 0.12);
  padding: 0 30px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.navIcon {
  font-size: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.16);
  cursor: pointer;
}

.newsContainer {
  margin: 2rem 0;
}

.newsContainerInner {
  padding: 20px 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
}

.newsContainerInner > div:nth-child(1) {
  width: 70%;
  height: 100%;
  flex: 1;
}

.newsContainerInner > div:nth-child(2) {
  height: 100%;
  width: 30%;
  border-left: 1px solid rgba(241, 231, 234, 0.13);
}

.mobileNewsContainer {
  display: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .newsContainerInner {
    flex-direction: column;
  }

  .topStoriesHeader {
    width: 100%;
    border-left: none;
    padding: 0 30px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .mobileSecondHeader {
    border-top: 2px dashed rgba(255, 217, 27, 0.17);
    margin-bottom: 2rem;
  }

  .newsContainerInner > div:nth-child(1) {
    width: 100%;
  }

  .newsContainerInner > div:nth-child(2) {
    display: none;
  }

  .header > div:nth-child(2) {
    display: none;
  }

  .mobileNewsContainer {
    display: block;
  }

  .header {
    font-size: 20px;
    line-height: 23px;
    padding: 10px 0;
  }

  .topStoriesHeader {
    padding: 0 10px;
  }

  .newsContainerInner > div:nth-child(2) {
    border: none;
  }
}

@media screen and (max-width: 767px) {
  .newsContainerInner {
    flex-direction: column;
  }

  .topStoriesHeader {
    width: 100%;
    border-left: none;
    padding: 0 30px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .mobileSecondHeader {
    border-top: 2px dashed rgba(255, 217, 27, 0.17);
    margin-bottom: 2rem;
  }

  .newsContainerInner > div:nth-child(1) {
    width: 100%;
  }

  .newsContainerInner > div:nth-child(2) {
    display: none;
  }

  .header > div:nth-child(2) {
    display: none;
  }

  .mobileNewsContainer {
    display: block;
  }

  .header {
    font-size: 20px;
    line-height: 23px;
    padding: 10px 0;
  }

  .topStoriesHeader {
    padding: 0 10px;
  }
}

.container {
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  padding-left: 34px;
}

.homeStoriesLeft {
  width: 50%;
  padding: 0 1rem;
  border-right: 1px solid rgba(241, 231, 234, 0.13);
}

.headlineLeft {
  display: flex;
  align-items: stretch;
  gap: 23px;
  margin: 20px 0;
  cursor: pointer;
  padding: 0 1rem;
}

.headlineLeft > .imageSection {
  width: 50%;
  position: relative;
  height: 190px;
}

.headlineLeft > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headlineLeft > .imageSection > .tag,
.headlineRight > .imageSection > .tag {
  background: #ffffff;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 900;
  font-size: 11.5777px;
  line-height: 16px;
  color: #000000;
  padding: 0.5rem 1rem;
  position: absolute;
  top: -10px;
  left: -10px;
}

.headlineLeft > .description {
  width: 50%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
}

.headlineLeft > .description > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 10px;
}

.headlineLeft > .description > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #bab5b5;
  margin-bottom: 10px;
}

.headlineLeft > .description > a:nth-child(3) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #ffffff;
}

.homeStoriesRight {
  width: 50%;
  padding: 0 2rem;
  flex: 1;
  box-sizing: border-box;
  border-right: 1px solid rgba(241, 231, 234, 0.13);
}

.headlineRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 50%;
  margin-bottom: 2rem;
}

.headlineRight > .imageSection {
  height: 60%;
  width: 100%;
  position: relative;
}

.headlineRight > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 250px;
}

.headlineRight > .description {
  height: 40%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;
}

.headlineRight > .description > div:nth-child(1) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
  margin-top: 12px;
}

.headlineRight > .description > div:nth-child(2) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.headlineRight > .description > a:nth-child(3) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #ffffff;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
    padding: 0 1rem;
  }

  .homeStoriesLeft {
    width: 100%;
    padding: 0.5rem;
    border: none;
  }

  .homeStoriesRight {
    width: 100%;
    padding: 0rem;
    border: none;
  }

  .headlineLeft {
    margin-bottom: 2rem;
    flex-direction: column;
  }

  .headlineLeft > .imageSection {
    width: 100%;
  }

  .headlineLeft > .description {
    width: 100%;
  }

  .headlineRight > .description > div:nth-child(2) {
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
    padding: 0 1rem;
  }

  .homeStoriesLeft {
    width: 100%;
    padding: 0.5rem 0;
    border: none;
  }

  .homeStoriesRight {
    width: 100%;
    padding: 0rem;
    border: none;
  }

  .headlineLeft {
    margin-bottom: 2rem;
    flex-direction: column;
    padding: 0;
    gap: 15px;
  }

  .headlineLeft > .imageSection {
    width: 100%;
  }

  .headlineLeft > .description {
    width: 100%;
  }

  .headlineRight > .description > div:nth-child(2) {
    font-size: 18px;
    line-height: 25px;
  }

  .headlineRight > .description > a:nth-child(3) {
    font-size: 12px;
    line-height: 19px;
  }
}

.container {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-top: 1px solid #ffd91b;
}

.circlrFilter {
  position: absolute;
  height: 205px;
  width: 205px;
  background: #ffd91b;
  opacity: 0.28;
  right: 0;
  bottom: 0;
  filter: blur(200px);
}

.header {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-align: center;
}

.socialicons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  padding-bottom: 46px;
}

.footerBottom {
  border-top: 1px solid rgba(241, 231, 234, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 46px 0;
  width: 100%;
}

.logoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14.6456px;
  line-height: 20px;
  color: #ffffff;
}

.logoSection > img {
  height: 89px;
  width: 79px;
}

.footerLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #ffffff;
  padding: 0 1rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .footerBottom {
    flex-direction: column;
  }

  .footerLinks {
    padding: 0 1rem;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .footerBottom {
    flex-direction: column;
  }

  .footerLinks {
    padding: 0 1rem;
    flex-wrap: wrap;
  }
}

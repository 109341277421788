.header {
  height: 38px;
  width: 259px;
  background: #0b0809;
  border: 0.609654px solid rgba(255, 255, 255, 0.2);
  border-radius: 6.09654px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    border: none;
  }
}

@media screen and (max-width: 767px) {
  .header {
    border: none;
  }
}

.homeStoriesRight {
  border-bottom: 1px solid rgba(241, 231, 234, 0.13);
  padding-bottom: 1rem;
}

.headlineRight {
  display: flex;
  align-items: center;
  gap: 59px;
}

.headlineRight > .imageSection {
  width: 60%;
  position: relative;
  max-height: 400px;
  overflow: hidden;
}

.imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headlineRight > .description {
  width: 40%;
}

.headlineRight > .description > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 1rem;
}

.headlineRight > .description > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #bab5b5;
  margin-bottom: 3rem;
}

.headlineRight > .description > a:nth-child(3) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ffffff;
}

.homeStoriesLeft {
  display: flex;
  align-items: stretch;
  gap: 31.38px;
  padding-top: 1rem;
}

.headlineLeft {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  gap: 38px;
  flex: 1;
  padding: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.headlineLeft:hover {
  background: #212121;
}

.headlineLeft > .imageSection {
  height: 60%;
}

.headlineLeft {
  width: 100%;
}

.headlineLeft > .description {
  height: 40%;
  box-sizing: border-box;
}

.headlineLeft > .description > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 29px;
}

.headlineLeft > .description > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #acacac;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .headlineRight {
    flex-direction: column;
  }

  .headlineRight > .imageSection {
    width: 100%;
  }

  .headlineRight > .description {
    width: 100%;
  }

  .homeStoriesLeft {
    flex-direction: column;
  }

  .headlineLeft {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .headlineRight {
    flex-direction: column;
    gap: 10px;
  }

  .homeStoriesRight {
    border-bottom: 1px solid rgba(241, 231, 234, 0.13);
    padding-bottom: 1rem;
  }

  .headlineRight > .imageSection {
    width: 100%;
  }

  .headlineRight > .description {
    width: 100%;
  }

  .headlineRight > .description > div:nth-child(1) {
    font-size: 180px;
    line-height: 27px;
  }

  .homeStoriesLeft {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    gap: 15.38px;
  }

  .headlineLeft {
    width: 100%;
    padding: 0rem;
  }

  .headlineLeft > .description > div:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 1rem;
  }

  .headlineRight > .description > div:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0.5rem;
  }

  .headlineRight > .description > div:nth-child(2) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
}

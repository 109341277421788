.homeStoriesRight {
  display: flex;
  align-items: stretch;
  gap: 4rem;
}

.headlineRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.headlineRight > .imageSection {
  width: 100%;
  position: relative;
  height: 212px;
  border-radius: 0px;
}

.imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headlineRight > .description {
  width: 100%;
  height: calc(100% - 212px);
}

.headlineRight > .description > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.headlineRight > .description > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 9.4732px;
  line-height: 13px;
  color: #acacac;
  margin-top: 12px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .homeStoriesRight {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .homeStoriesRight {
    flex-direction: column;
  }

  .headlineRight {
    gap: 10px;
  }

  .headlineRight > .description > div:nth-child(1) {
    font-size: 20px;
    line-height: 30px;
  }

  .headlineRight > .description > div:nth-child(2) {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 700;
    font-size: 9.4732px;
    line-height: 13px;
    color: #acacac;
    margin-top: 22px;
  }
}

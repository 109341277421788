.container {
  border-top: 1px solid #1c1c1c;
  border-bottom: 1px solid #1c1c1c;
  text-align: center;
  padding: 1rem 1.5rem;
  background: var(--foregroundBlack);
  padding: 2rem 0 0 0;
}

.header {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 1.5rem;
}

.match {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 32px;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.match:hover {
  background: #212121;
}

.match > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.match > div:nth-child(1),
.match > div:nth-child(3) {
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
}

.match > div:nth-child(1) > div,
.match > div:nth-child(3) > div {
  height: 52.67px;
  width: 52.6px;
}

.match > div:nth-child(1) > span,
.match > div:nth-child(3) > span {
  width: 70px;
}

.match > div:nth-child(2) > div:nth-child(1) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
}

.match > div:nth-child(2) > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 900;
  font-size: 24.1423px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.match > div:nth-child(2) > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
}

.liveIcon {
  height: 4px;
  width: 4px;
  border-radius: 50%;
}

.match > div:nth-child(2) > div:nth-child(3) > span:nth-child(1)::before {
  content: "";
}

.noNews {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

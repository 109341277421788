.container {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  box-sizing: border-box;
}

@keyframes appear {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes easeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  background: var(--foregroundBlack);
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 2.5%;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  position: relative;
}

.logoSection,
.search {
  align-self: center;
}
.navItemSection {
  align-self: stretch;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.logosection {
  display: flex;
  align-items: center;
}

.logoSection {
  height: 89px;
  width: 79px;
  position: relative;
  z-index: 4;
}

.logoSection > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.navItemSection {
  display: flex;
  align-items: center;
  gap: 1rem;
  align-items: center;
}

.navItemSection > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  color: rgba(255, 255, 255, 0.5);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}

.moreSection {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  position: relative;
}

.moreOptions {
  position: absolute;
  width: 200px;
  top: 80px;
  left: 20px;
  background: var(--foregroundBlack);
  box-shadow: 0px 24px 38px 3px hsla(0, 0%, 0%, 0.14),
    0px 9px 46px 8px hsla(0, 0%, 0%, 0.12),
    0px 11px 15px -7px hsla(0, 0%, 0%, 0.2);
  border-radius: 8px;
}

.moreOptions > a > .navItem {
  padding: 8px;
  transition: all 0.3s ease-in-out;
}

.moreOptions > .activeNav {
  color: #ffd91b;
}

.moreOptions > a:last-child > div {
  border-radius: 0px 0 8px 8px;
}

.moreOptions > a:first-child > div {
  border-radius: 8px 8px 0px 0px;
}

.moreOptions > a > .navItem:hover {
  background: #212121;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 5px;
  align-items: center;
  transition: all 0.3s ease-in-out;
  position: relative;
  height: 100%;
  animation: easeIn 0.3s ease-in-out;
}

.activeIndicator {
  width: 100%;
  background-color: var(--yellow);
  height: 4px;
  border-radius: 100px;
  animation: appear 0.3s ease-in-out;
}

.responsivemenu,
.searchSection {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.search {
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;
  width: 50%;
  justify-content: flex-end;
  animation: easeIn 0.3s ease-in-out;
  height: 42px;
  width: 304px;
  border-radius: 8px;
  position: relative;
}

.search > i {
  animation: easeIn 0.3s ease-in-out;
  position: absolute;
  left: 12px;
  color: #4f4f4f;
}

.search > input {
  height: 42px;
  width: 304px;
  text-indent: 40px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  border-radius: 8px;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #4f4f4f;
  animation: easeIn 0.3s ease-in-out;
}

.search > input::placeholder {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #4f4f4f;
}

.sideNav {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  width: 0;
  background: var(--foregroundBlack);
  transition: 0.3s;
  box-sizing: border-box;
}

.mobileSearchContainer {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 20;
  height: 52px;
  left: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  padding: 0.5rem;
}

.mobileSearchContainer > i {
  animation: easeIn 0.3s ease-in-out;
  position: absolute;
  left: 12px;
  color: #4f4f4f;
}

.mobileSearchContainer > input {
  height: 100%;
  width: 100%;
  text-indent: 20px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  border-radius: 8px;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #4f4f4f;
  animation: easeIn 0.3s ease-in-out;
}

.sideNavInner {
  min-width: 100vw;
  height: 100%;
}

.sideContainer {
  padding: 0 1rem;
  background: var(--backgroundblack);
  color: #8f8b8b;
}

.sideContainer > a > .navItem {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-top: 3rem;
}

.sideContainer > .activeNav {
  color: #ffd91b;
}

.header > img {
  height: 54px;
  width: 54px;
}

.logoSection > img {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  height: 72px;
}

.btnClose {
  color: #ffffff;
  background: transparent;
  border: none;
  font-size: 30px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 0 1rem;
    height: 72px;
  }

  .navItemSection,
  .dropdownSection {
    display: none;
  }

  .search {
    display: none;
  }

  .container {
    position: relative;
  }

  .logoSection {
    left: 0;
    right: 0;
    /* margin: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 54px;
  }

  .responsivemenu,
  .searchSection {
    display: flex;
    font-size: 1.2rem;
    gap: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 1rem;
    height: 72px;
  }

  .navItemSection,
  .dropdownSection {
    display: none;
  }

  .search {
    display: none;
  }

  .container {
    position: relative;
  }

  .logoSection {
    left: 0;
    right: 0;
    /* margin: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 54px;
  }

  .responsivemenu,
  .searchSection {
    display: flex;
    font-size: 1.2rem;
    gap: 2rem;
  }
}

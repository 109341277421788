.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.mobileContainer {
  display: none;
  height: 100%;
  width: 100%;
}

.mainContainer {
  height: 100%;
  width: 100%;
}

.cancel {
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--foregroundBlack);
  cursor: pointer;
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .mainContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .mainContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}

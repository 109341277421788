.container {
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  padding-left: 34px;
}

.homeStoriesLeft {
  width: 50%;
  padding: 0 1rem;
}

.headlineLeft {
  display: flex;
  align-items: stretch;
  gap: 23px;
  margin: 15px 0;
  cursor: pointer;
}

.headlineLeft > .imageSection {
  width: 50%;
  position: relative;
  height: 170px;
}

.headlineLeft > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headlineLeft > .imageSection > .tag,
.headlineRight > .imageSection > .tag {
  background: #ffffff;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 900;
  font-size: 11.5777px;
  line-height: 16px;
  color: #000000;
  padding: 0.5rem 1rem;
  position: absolute;
  top: -10px;
  left: -10px;
}

.headlineLeft > .description {
  width: 50%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
}

.headlineLeft > .description > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.headlineLeft > .description > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #bab5b5;
}

.headlineLeft > .description > a:nth-child(3) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ffffff;
}

.homeStoriesRight {
  width: 50%;
  padding: 0 2.5rem;
  flex: 1;
  border-left: 1px solid rgba(241, 231, 234, 0.13);
  box-sizing: border-box;
}

.headlineRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.headlineRight > .imageSection {
  /* height: 70%; */
  height: 450px;
  width: 100%;
  position: relative;
}

.headlineRight > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headlineRight > .description {
  height: calc(100% - 450px);
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
}

.headlineRight > .description > div:nth-child(1) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #acacac;
  margin: 8px 0 0 0;
}

.headlineRight > .description > div:nth-child(2) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.headlineRight > .description > a:nth-child(3) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ffffff;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
    padding: 0 1rem;
  }

  .homeStoriesLeft {
    width: 100%;
    padding: 0.5rem;
  }

  .homeStoriesRight {
    width: 100%;
    padding: 0rem;
    border-left: none;
  }

  .headlineLeft {
    margin-bottom: 2rem 0;
  }
}

@media screen and (max-width: 767px) {
  .container {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
    padding: 0 1rem;
  }

  .homeStoriesLeft {
    width: 100%;
    padding: 0.5rem;
    order: 1;
  }

  .homeStoriesRight {
    width: 100%;
    padding: 0rem;
    border-left: none;
  }

  .headlineLeft {
    margin-bottom: 2rem 0;
    flex-direction: column;
  }

  .headlineLeft > .imageSection {
    width: 100%;
  }

  .headlineLeft > .description {
    width: 100%;
  }

  .headlineRight > .description > div:nth-child(2) {
    font-size: 24px;
    line-height: 32px;
  }

  .headlineRight > .description > div:nth-child(3) {
    font-size: 14px;
    line-height: 21px;
  }

  .headlineRight > .description > a:nth-child(4) {
    font-size: 12px;
    line-height: 18px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
  cursor: auto;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 540px;
  height: 540px;
  z-index: 100;
  overflow: hidden;
  background-color: #000000;
  margin: auto;
  animation: appear 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal > div {
  width: 100%;
  height: 100%;
  text-align: left;
}

.footer {
  text-align: right;
  margin-right: 3rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .modal {
    width: 60%;
    height: 35vh;
  }
}

@media (max-width: 767px) {
  .modal {
    width: 90%;
    height: 50vh;
  }
}

.container {
  padding: 2rem;
}

.container > h4 {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #ffd91b;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.container > form {
  margin-top: 2rem;
}

.container > form > label {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
}

.container > form > input {
  width: 100%;
  display: block;
  height: 50px;
  background: transparent;
  border: 2px solid #fff;
  margin: 1rem 0;
  border-radius: 10px;

  font-family: "Georgia";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
  padding: 1rem;
}

.container > form > button {
  padding: 0.5rem 1rem;
  background: #ffd91b;
  border: none;
  margin: 4rem auto;
  display: block;
  cursor: pointer;

  font-family: "Georgia";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
  }

  .container > form {
    margin-top: 1.5rem;
  }

  .container > form > label {
    font-size: 16px;
    line-height: 140%;
  }

  .container > form > input {
    width: 100%;
    height: 40px;

    font-size: 16px;
    padding: 1rem;
  }

  .container > form > button {
    margin: 2rem auto;
    font-size: 16px;
    line-height: 140%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
  }

  .container > form {
    margin-top: 1.5rem;
  }

  .container > form > label {
    font-size: 16px;
    line-height: 140%;
  }

  .container > form > input {
    width: 100%;
    height: 40px;

    font-size: 16px;
    padding: 1rem;
  }

  .container > form > button {
    margin: 2rem auto;
    font-size: 16px;
    line-height: 140%;
  }
}

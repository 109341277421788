.container {
  padding: 2.5rem 0;
}

.header {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #ffd91b;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 0;
  border-bottom: 2px dashed rgba(255, 217, 27, 0.17);
  border-top: 2px dashed rgba(255, 217, 27, 0.17);
}

.newsContainer {
  margin: 2rem 0;
  display: flex;
  align-items: center;
}

.newsContainerInner {
  padding: 0px 0;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
}

.homeStoriesLeft {
  width: 70%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.homeStoriesLeft > .homeStoriesLeftTop {
  width: 100%;
  height: 50%;
  flex: 1;
  padding: 1rem;
}

.homeStoriesLeft > .homeStoriesLeftBottom {
  height: 50%;
}

.homeStoriesLeft > .homeStoriesLeftTop > .headlineRight {
  display: flex;
  align-items: center;
  gap: 30px;
  height: 100%;
}

.homeStoriesLeft > .homeStoriesLeftTop > .headlineRight > .imageSection {
  width: 50%;
  height: 100%;
}

.homeStoriesLeft > .homeStoriesLeftTop > .headlineRight > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeStoriesLeft > .homeStoriesLeftTop > .headlineRight > .description {
  width: 50%;
}

.homeStoriesLeft
  > .homeStoriesLeftTop
  > .headlineRight
  > .description
  > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.homeStoriesLeft
  > .homeStoriesLeftTop
  > .headlineRight
  > .description
  > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
  margin: 1rem 0;
}

.homeStoriesLeft > .homeStoriesLeftBottom {
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 4rem;
  border-top: 2px solid rgba(241, 231, 234, 0.13);
  border-bottom: 2px solid rgba(241, 231, 234, 0.13);
  height: 50%;
  padding: 1rem 2rem;
}

.homeStoriesLeft > .homeStoriesLeftBottom > .headlineRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 18px;
}

.homeStoriesLeft > .homeStoriesLeftBottom > .headlineRight > .imageSection {
  width: 100%;
  height: 50%;
}

.homeStoriesLeft
  > .homeStoriesLeftBottom
  > .headlineRight
  > .imageSection
  > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeStoriesLeft > .homeStoriesLeftBottom > .headlineRight > .description {
  width: 100%;
  height: 50%;
}

.homeStoriesLeft
  > .homeStoriesLeftBottom
  > .headlineRight
  > .description
  > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.homeStoriesLeft
  > .homeStoriesLeftBottom
  > .headlineRight
  > .description
  > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
  margin: 1rem 0;
}

.homeStoriesRight {
  border-left: 2px solid rgba(241, 231, 234, 0.13);
  border-bottom: 2px solid rgba(241, 231, 234, 0.13);
  width: 30%;
  padding: 1rem 2rem;
}

.homeStoriesRight > .headlineRight {
  margin-bottom: 0.5rem;
}

.homeStoriesRight > .headlineRight > .imageSection {
  height: 250.7px;
}

.homeStoriesRight > .headlineRight > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeStoriesRight > .headlineRight > .description {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  margin-top: 1rem;
}

.homeStoriesBottomBottom {
  display: flex;
  align-items: flex-start;
}

.homeStoriesBottomBottomLeft {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 75%;
  flex-wrap: wrap;
  padding: 2rem;
  cursor: pointer;
}

.homeStoriesBottomBottomLeft > .headlineRight {
  width: 30%;
  height: 381px;
  transition: all 0.3s ease-in-out;
}

.homeStoriesBottomBottomLeft > .headlineRight:hover {
  background: #212121;
}

.homeStoriesBottomBottomLeft > .headlineRight > .imageSection {
  height: 203.2px;
  border-radius: 0px;
  margin-bottom: 30px;
}

.homeStoriesBottomBottomLeft > .headlineRight > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeStoriesBottomBottomLeft
  > .headlineRight
  > .description
  > div:nth-child(1) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.homeStoriesBottomBottomLeft
  > .headlineRight
  > .description
  > div:nth-child(2) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 9.7193px;
  line-height: 13px;
  color: #acacac;
  margin-top: 1rem;
}

.homeStoriesBottomBottomRight {
  width: 25%;
}

.homeStoriesBottomBottomRight > div {
  margin-bottom: 8rem;
}

.loading {
  margin-top: 2rem;
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.readMore {
  text-align: center;
  width: 100%;
  color: #ffd91b;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  margin-top: 1rem;
}

.noNews {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .newsContainerInner {
    flex-direction: column;
  }

  .homeStoriesLeft {
    width: 100%;
  }

  .homeStoriesRight {
    width: 100%;
  }

  .homeStoriesLeft > .homeStoriesLeftTop > .headlineRight > .imageSection {
    width: 50%;
    height: 249px;
  }

  .homeStoriesLeft > .homeStoriesLeftBottom {
    width: 100%;
    flex-direction: column;
    height: auto;
  }

  .homeStoriesLeft > .homeStoriesLeftBottom > .headlineRight {
    width: 100%;
  }

  .homeStoriesBottomBottom {
    flex-direction: column;
  }

  .homeStoriesBottomBottomLeft {
    flex-direction: column;
  }

  .homeStoriesBottomBottomLeft > .headlineRight {
    width: 100%;
  }

  .homeStoriesBottomBottomLeft {
    width: 100%;
  }

  .homeStoriesBottomBottomRight {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .newsContainerInner {
    flex-direction: column;
  }

  .homeStoriesLeft > .homeStoriesLeftTop {
    padding: 1rem;
  }

  .homeStoriesLeft > .homeStoriesLeftTop > .headlineRight {
    flex-direction: column;
  }

  .homeStoriesLeft > .homeStoriesLeftTop > .headlineRight > .description {
    width: 100%;
  }

  .homeStoriesLeft {
    width: 100%;
  }

  .homeStoriesRight {
    width: 100%;
    border-left: none;
    border-bottom: none;
    padding: 1rem;
  }

  .homeStoriesLeft > .homeStoriesLeftTop > .headlineRight > .imageSection {
    width: 100%;
    height: 249px;
  }

  .homeStoriesLeft > .homeStoriesLeftBottom {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 1rem;
  }

  .homeStoriesLeft > .homeStoriesLeftBottom > .headlineRight {
    width: 100%;
  }

  .homeStoriesBottomBottom {
    flex-direction: column;
  }

  .homeStoriesBottomBottomLeft {
    flex-direction: column;
  }

  .homeStoriesBottomBottomLeft > .headlineRight {
    width: 100%;
  }

  .homeStoriesBottomBottomLeft {
    width: 100%;
  }

  .homeStoriesBottomBottomLeft {
    width: 100%;
    padding: 1rem;
  }

  .homeStoriesBottomBottomRight {
    width: 100%;
  }

  .homeStoriesBottomBottomLeft
    > .headlineRight
    > .description
    > div:nth-child(1) {
    font-size: 14px;
    line-height: 21px;
  }

  .homeStoriesRight > .headlineRight > .description {
    font-size: 14px;
    line-height: 21px;
  }

  .homeStoriesLeft
    > .homeStoriesLeftTop
    > .headlineRight
    > .description
    > div:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
  }

  .homeStoriesLeft
    > .homeStoriesLeftBottom
    > .headlineRight
    > .description
    > div:nth-child(1) {
    font-size: 14px;
    line-height: 21px;
  }
}

.dateSection {
  padding: 2rem 0;
}

.adContainer {
  margin: 2rem 0;
}

.loading {
  height: 80vh;
  margin-top: 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .dateSection {
    display: none;
  }

  .adContainer {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .dateSection {
    display: none;
  }

  .adContainer {
    display: none;
  }
}

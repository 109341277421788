.container {
  padding: 5rem 0;
  display: flex;
  align-items: flex-start;
  gap: 52px;
}

.leftSection {
  width: 75%;
}

.nav {
  color: #ffd91b;
  font-size: 1.5rem;
}

.headline {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  color: #f2f2f2;
  margin: 1.5rem 0;
}

.byLineAndShareSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.byLineAndShareSection > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #828282;
}

.byLineAndShareSection > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 27px;
}

.byLineAndShareSection > div:nth-child(2) > span {
  height: 49px;
  width: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 48, 48, 0.44);
  border: 0.4px solid #ffd91b;
  border-radius: 50%;
  cursor: pointer;
}

.imageSection {
  width: 100%;
  height: 562.54px;
  margin: 2rem 0;
}

.imagesSection {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 2rem;
  border: 30px solid green;
}

.imagesSection > img {
  height: 362.54px;
  flex-basis: 48%;
  width: 48%;
  object-fit: cover;
}

.imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tagSection {
  display: flex;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
  padding: 2rem 0;
  border-top: 1px solid rgba(241, 231, 234, 0.18);
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.key {
  padding: 4px 14px;
  background: #111111;
  border: 0.3px solid #ffd91b;
  border-radius: 4px;
}

.textBody {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 41px;
  color: #ffffff;
}

.textBody > figure {
  margin: 2rem 0;
  color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paragraph {
  margin-bottom: 2rem;
}

.rightSection {
  width: 25%;
}

.adSection {
  margin: 4rem 0;
}

.loading {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adContainer {
  margin-bottom: 2rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 5rem 0;
    display: flex;
    align-items: flex-start;
    gap: 52px;
    flex-direction: column;
  }

  .leftSection {
    width: 100%;
  }

  .rightSection {
    width: 100%;
  }

  .headline {
    font-size: 32px;
    line-height: 45px;
  }

  .byLineAndShareSection {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }

  .byLineAndShareSection > div:nth-child(1) {
    font-size: 16px;
    line-height: 27px;
  }

  .textBody {
    font-size: 18px;
    line-height: 36px;
  }

  .adContainer {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem 0;
    display: flex;
    align-items: flex-start;
    gap: 52px;
    flex-direction: column;
  }

  .leftSection {
    width: 100%;
  }

  .rightSection {
    width: 100%;
  }

  .headline {
    font-size: 32px;
    line-height: 45px;
  }

  .byLineAndShareSection {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }

  .byLineAndShareSection > div:nth-child(1) {
    font-size: 16px;
    line-height: 27px;
  }

  .textBody {
    font-size: 18px;
    line-height: 36px;
  }

  .adContainer {
    display: none;
  }

  .imagesSection > img {
    flex-basis: 100%;
    width: 100%;
  }
}

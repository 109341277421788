.container {
  padding: 0 59px 0 25px;
}

.headline {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(241, 231, 234, 0.18);
  display: block;
}

.headline:last-child {
  border-bottom: none;
}

.headline > div:nth-child(1) {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bab5b5;
  margin-bottom: 0.7rem;
}

.headline > div:nth-child(2) {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .headline > div:nth-child(1) {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .headline > div:nth-child(2) {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .headline > div:nth-child(1) {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .headline > div:nth-child(2) {
    font-size: 18px;
    line-height: 24px;
  }
}
